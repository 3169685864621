import './AboutMe.css'

function AboutMe() {
  return (
    <div className="section">
      <div className="about-me-text">
        <div className="about-me">
          Hello, my name is <span className="name">Lauren Koch</span> and I'm a fullstack software developer. I've previously worked with Javascript, React, Node, Python, and Django and I'm currently learning Typescript.
        </div>
        <div>Check out some of my work below!</div>
      </div>
    </div>
  )
}

export default AboutMe