import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons'
import './Header.css'

function Header() {
  return (
    <div className="header-container">
      <a className="link header-link" href='https://github.com/laurenkch' target="blank">
        <FontAwesomeIcon icon={faGithub} />
      </a>
      <a className="link header-link" target="blank" href='https://www.linkedin.com/in/laurenkch/'>
        <FontAwesomeIcon icon={faLinkedin} />
      </a>
      <a className="link header-link" target="blank" href="mailto:laurenkochdev@gmail.com">
        <FontAwesomeIcon icon={faEnvelope} />
      </a>
    </div>
  )
}

export default Header