import './Work.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

function Work() {

  type Work = {
    link: string;
    title: string;
    image: string;
    descriptionPoints: Array<string>
  }

  const WORKS = [
    {
      link: 'https://trailmix-lkoch.herokuapp.com/',
      title: 'TrailMix',
      image: './images/trailmix-screenshot.png',
      descriptionPoints: [
        'Application listing hiking trails in Upstate South Carolina',
        'Javascript and Python using a React / Django framework',
        'Integrated React Leaflet, Fuse.js, and OpenWeather API',
        'Downloadable maps of the trailhead area',
        'Dynamic mobile and desktop design'
      ]
    },
    {
      link: 'https://fullstack-restaurant-app-lkoch.herokuapp.com/',
      title: 'Mock Restaurant',
      image: './images/restaurant-screenshot.png',
      descriptionPoints: [
        'Javascript and Python using a React / Django framework',
        'User view that allows for creating, editing, deleting, and placing a food order.',
        'Restaurant view for tracking active and previously completed orders'
      ]
    },
    {
      link: 'https://laurenkch.github.io/pixel-perfect/',
      title: 'Pixel Perfect Design Project',
      image: './images/pixel-perfect-screenshot.png',
      descriptionPoints: [
        'CSS focused project matching a given layout with a responsive design',
      ]
    },
    {
      link: 'https://laurenkch.github.io/js-responsive-calculator/',
      title: 'Calculator',
      image: './images/calculator-screenshot.png',
      descriptionPoints: [
        'Simple calculator recreation in Javascript with a responsive design',

      ]
    }
  ]

  function generateDescriptionPoints(listOfPoints: Array<string>): React.ReactElement {
    return (
      <ul className="fa-ul list">
        {listOfPoints.map((point: string): React.ReactElement =>
          <li>
            < FontAwesomeIcon className="list-icon" icon={faArrowRight} listItem />
            {point}
          </li>)
        }
      </ul >
    )
  }

  function generateWorkItems(listOfWorks: Array<Work>): React.ReactElement[] {
    return listOfWorks.map((work: Work): React.ReactElement => {
      const { link, descriptionPoints, image, title } = work
      return (
        <div className="section" key="work.title">
          <div className="work-wrapper">
            <div className="work-content">
              <a href={link} target="blank" className="title">{title}</a>
              <div className="work-sub-section">
                {generateDescriptionPoints(descriptionPoints)}
              </div>
              <div className="work-sub-section">
                <a href={link} target="blank">
                  <div className="photo">
                    <img src={image} />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      )
    })
  }

  return (
    <>
      {generateWorkItems(WORKS)}
    </>
  )
}

export default Work