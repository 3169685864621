import './App.css'
import AboutMe from './Components/AboutMe/AboutMe'
import Contact from './Components/Contact/Contact'
import Header from './Components/Header/Header'
import Work from './Components/Work/Work'

function App() {

  const documentHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--doc-height', `${window.innerHeight}px`)
  }
  window.addEventListener('resize', documentHeight)
  documentHeight()

  return (
    <div className="App">
      <Header />
      <div className="section-container">
        <AboutMe />
        <Work />
        <Contact />
      </div>
    </div >
  )
}

export default App
